@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.selection-tile {
  position: relative;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 0 9px rgba($shadow-default, 0.34);
  display: block;
  min-height: 330px;
  transition: all 0.2s;
  width: 250px;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 30px 18px;

  &:not(.selection-tiles--disabled):hover {
    border: 1px solid $persian-green;
    box-shadow: 0 0 10px rgba($persian-green, 0.6);
    text-decoration: none;
    transform: scale(1.033);
  }
}

.selection-tiles--disabled {
  cursor: not-allowed;
  background-color: $white-smoke;
  border: 1px solid $platinum;
}

.selection-tile__title {
  color: $charcoal;
  font-size: 18px;
  font-weight: 500;
}

.selection-tile__subtitle {
  color: $charcoal;
  font-size: 15px;
}

.selection-tile__description {
  color: $charcoal;
  font-size: 13px;
  font-weight: lighter;
  margin-top: 15px;
}

.selection-tile__image {
  max-height: 120px;
  margin-bottom: 15px;
}
